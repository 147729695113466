import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import Button, {ButtonProps} from '@mui/material/Button';

import { LoginButton, LogoutButton, useAuth } from 'components/AuthProvider';

function toDollar(amount: number): string {
  return "$" + (amount / 100).toFixed(2);
}

/// instantiate api call
const coreApi = axios.create({
  baseURL: process.env.REACT_APP_CORE_ROOT,
});

function UserDropdown() {
  const { user, logout, getAccessTokenSilently } = useAuth();

  const userInfoQuery = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_AUDIENCE,
          scope: 'user:full-access',
        }
      });
      if (!accessToken) throw new Error('No access token');
  
      const response = await coreApi.get(
        '/user',
        {          
          params: { userId: user!.sub!},
          headers: {Authorization: `Bearer ${accessToken}`}
        }       
      );
      return response.data;
    },
    retry: 3,
    // retryDelay: attempt => Math.min(attempt > 0 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
  })

  useEffect(() => {
    if (userInfoQuery.isError) {
      console.error('Error fetching user info:', userInfoQuery.error);
      alert('Failed to fetch user info. Please log back in.')
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });
    }
  }, [userInfoQuery.isError, userInfoQuery.error]);

  let content;
  if (userInfoQuery.isLoading) content = (
    <List style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ListItem>
        <CircularProgress />
      </ListItem>
      <ListItem>
        <LogoutButton 
          color="primary"
          variant="text"
          size="small"
          component="a"
          fullWidth
        />
      </ListItem>
      <ListItem style={{ marginTop: 'auto' }}>
        <DeleteAccountButton
          color="error"
          variant="outlined"
          size="small"
          component="a"
          fullWidth
        />
      </ListItem>
    </List>
  )
  else if (userInfoQuery.isError) content = (
    <List style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ListItem>
        <ListItemText primary="Unable to fetch user info."/>
      </ListItem>
      <ListItem>
        <LogoutButton 
          color="primary"
          variant="text"
          size="small"
          component="a"
          fullWidth
        />
      </ListItem>
      <ListItem style={{ marginTop: 'auto' }}>
        <DeleteAccountButton
          color="error"
          variant="outlined"
          size="small"
          component="a"
          fullWidth
        />
      </ListItem>
    </List> 
  )
  else if (userInfoQuery.data) content = (
    <List style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ListItem>
        <ListItemText primary={userInfoQuery.data.email}/>
      </ListItem>
      <ListItem>
        <ListItemText primary={"available tokens"} secondary={toDollar(userInfoQuery.data.currentAmount)}/>
      </ListItem>
      <ListItem>
        <ListItemText primary={"total amount tipped"} secondary={toDollar(userInfoQuery.data.totalTipAmount)}/>
      </ListItem>
      <ListItem>
        <LogoutButton 
          color="primary"
          variant="text"
          size="small"
          component="a"
          fullWidth
        />
      </ListItem>
      <ListItem style={{ marginTop: 'auto' }}>
        <DeleteAccountButton
          color="error"
          variant="outlined"
          size="small"
          component="a"
          fullWidth
        />
      </ListItem>
    </List>  
  )
  else content = (<> </>);
  
  return content;
}


function DeleteAccountButton(props: ButtonProps) {
  const { logout, user, getAccessTokenSilently } = useAuth();

  const handleDelete = async () => {
    try {
      /// get access token
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_AUDIENCE,
          scope: "user:full-access",
        }
      });
      if (!accessToken) throw new Error('No access token');

      /// send delete request
      const response = await coreApi.delete(
        '/user',
        {          
          params: { userId: user!.sub!},
          headers: {Authorization: `Bearer ${accessToken}`}
        }       
      );
      
      /// logout
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });

      console.log('User deleted:', response);
      alert('Account terminated.')

    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete account.');
    }
  }

  return <Button 
    {...props} 
    onClick={handleDelete}
  >
    Delete Account
  </Button>;
}

function UserAvatar() {
  /// auth
  const {user} = useAuth();

  /// user info draw
  const [open, setOpen] = useState<boolean>(false);
  const onClick = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };


  if (user) {
    return (
      <>
        <Avatar onClick={onClick(true)}>
          {user.email![0].toUpperCase()}
        </Avatar>
        <Drawer 
          anchor="right" 
          open={open} 
          onClose={onClick(false)}
        >
          <UserDropdown />

          {/* <DeleteAccountButton
            color="primary"
            variant="text"
            size="small"
            component="a"
            fullWidth

          /> */}
        </Drawer>
      </>
    )
  }
  else return <> </>;
}


export function UserComponent() {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <LoginButton                 
      color="primary"
      variant="text"
      size="small"
      component="a"
    />;
  } else {
    return ( <UserAvatar />)
  }
}