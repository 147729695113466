import { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Typography } from '@mui/material';

interface ImageProps {
  src: string;
  alt: string;
  loading?: 'lazy' | 'eager';
  style?: React.CSSProperties;
  aspectRatio: number;
  caption?: string;
}

export default function ImageComponent({ src, alt, loading = 'lazy', style, aspectRatio, caption = ''}: ImageProps, ) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
    <Box sx={{
      ...style,
      position: 'relative',
      marginBottom: '10px',
      '&::before': {
        content: '""',
        display: 'block',
        paddingTop: `${100 / aspectRatio}%`,
      },
      '& > *': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    }}>
    {isLoading && <Skeleton variant="rectangular" animation="wave" width="100%" sx={{
      ...style,
      position: 'relative',
      marginBottom: '10px'
    }}/>}
    <LazyLoadImage
      src={src}
      alt={alt}
      effect="blur"
      style={{...style, marginBottom: '10px',}}
      afterLoad={() => setIsLoading(false)}
    />
    </Box>
    <Typography variant="caption" display="block" gutterBottom       sx={{
        ...style,
        position: 'relative',
      }}
    >
        {caption}
    </Typography>
  </>
  );
}