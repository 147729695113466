import {useState, useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';

import {useAuth, LoginButton} from "components/AuthProvider";
import {GradientBackground} from "components/GradientBackground";
/// instantiate api call
const coreApi = axios.create({
  baseURL: process.env.REACT_APP_CORE_ROOT,
  // timeout: 1000,
  // headers: {
  //   'Content-Type': 'application/json'
  // }
});

interface PaymentFormState {
  isValid: boolean;
  comment: string;
}

function PaymentForm() {
  const navigate = useNavigate();
  const {isAuthenticated, user, getAccessTokenSilently} = useAuth();
  const [formData, setFormData] = useState<PaymentFormState>({
    isValid: true,
    comment: '', 
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCancel = () => {
    navigate('/about'); // Navigate to homepage or wherever you need
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    /// anonymous tip:
    if (!isAuthenticated) {
      const postData = {
        comment: formData.comment
      };
      // console.log('postData:', postData);
      
      try {
        // POST request to the server to initiate the checkout session
        const response = await coreApi.post('/checkout/create-session/anonymous', postData);
        console.log('Checkout session created:', response);
        window.location.href = response.data.redirectUrl;
      } catch (error) {
        console.error('Error during the checkout process:', error);
        alert('Failed to process payment. Please try again.');
      }
    }
    /// registered in user
    else {
      const postData = {
        userId: user!.sub!,
        email: user!.email!,
        comment: formData.comment,
      };
      // console.log('postData:', postData);
      
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH_AUDIENCE,
            scope: "user:full-access",
          }
        });
        if (!accessToken) throw new Error('No access token');
        
        /// POST request to the server to initiate the checkout session
        const response = await coreApi.post(
          '/checkout/create-session/registered', 
          postData,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );
        console.log('Checkout session created:', response);
        window.location.href = response.data.redirectUrl;
      } catch (error) {
        console.error('Error during the checkout process:', error);
        alert('Failed to process payment. Please try again.');
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}  
      sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      width: '500px', // Set a specific width or use 100% for full width
      maxWidth: '100%', // Ensures the box does not exceed the width of its parent
      margin: 'auto' // This centers the form horizontally if the parent is wider than the box
    }}>
      <TextField
        name="comment"
        label="Comment"
        variant="outlined"
        type="text"
        value={formData.comment}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
      />
      <Button type="submit" variant="contained" disabled={false}>
        Submit
      </Button>
      <Button onClick={handleCancel} variant="outlined">
        Cancel
      </Button>
    </Box>
  );
};

function LoginComments() {
  const {isAuthenticated} = useAuth();
  if (isAuthenticated) {
    return (
      <>
        <p>Your tip will be stored as compute tokens. You can use them to run existing or future demos on this site that require tokens.</p>
        <p>Thank you for your support! Feel free to comment below.</p>
        <br/><br/>
      </>
    );
  }
  else {
    return ( <>
      <p>You are currently tipping anonymously. If you would like to store your tip as compute tokens, please use the login button below.</p>
      <LoginButton
        color="primary"
        variant="text"
        size="small"
        component="a"
        // target="_blank"
      />
      <p>Thank you for your support! Feel free to comment below.</p>
      <br/><br/>
    </>
    );
  }
}

/// Payment preview page
export function Page() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successParam = queryParams.get('success');
    setIsSuccess(successParam === 'true');

    if (successParam === 'true') {
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    }
  }, [location]);

  /// invalidate cache when paymenet successful received

  return (
    <GradientBackground>
      {!isSuccess && <LoginComments/>}
      {!isSuccess && <PaymentForm/>}
      {isSuccess && <p>Payment successful!</p>}
    </GradientBackground>
  );
}
