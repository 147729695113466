import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Footer() {
  return (
    <Container maxWidth="sm" component="footer" sx={{ py: 6 }}>
      <Typography variant="body1" align="center" sx={{ mt: 2 }}>
        {"© 2024 KeyPointVision LLC | "}
        <Link href="https://www.linkedin.com/in/tyler-wu-b05a97190" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </Link>
        {' | '}
        <Link href="https://github.com/Taireyune" target="_blank" rel="noopener noreferrer">
          GitHub
        </Link>
      </Typography>
    </Container>
  )
}