import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, withAuthenticationRequired, useAuth0  } from '@auth0/auth0-react';

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

function AuthProvider({children}: Auth0ProviderWithConfigProps){
  const nagivate = useNavigate();

  const domain = process.env.REACT_APP_AUTH_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH_CALLBACK_URL;
  
  const onRedirectCallback = (appState?: AppState) => {
    nagivate(appState?.returnTo || window.location.pathname);
  };

  if (!domain || !clientId){
    console.log("Authentication parameters not set.")
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      authorizationParams={{
        // redirect_uri: window.location.origin,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export {
  AuthProvider, 
  useAuth0 as useAuth,
  withAuthenticationRequired as requireAuth
};