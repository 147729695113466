import React, {useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import MarkDown from 'markdown-to-jsx';

interface SectionProps {
  title: string;
  content: string;
}

export default function MarkDownBlock(props: SectionProps) {
  const { title, content } = props;

  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        '& .markdown': {
          py: 3,
        },
        marginBottom: 4,
        width: '100%',
      }}
    >
      <Typography 
        variant="h4" 
        gutterBottom 
        // style={{width: '100%', wordBreak: 'break-all'}}
      >
        {title}
      </Typography>
      {title && <Divider />}
      <MarkDown>{content}</MarkDown>
    </Grid>
  );
}

const post = `

`