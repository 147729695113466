import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';

import {
  useNavigate
} from "react-router-dom";

/// Button that says "Tip Me" and click to navigate to payment preview page
export function TipMe() {
  const navigate = useNavigate();
  return (
    <Button 
      onClick={() => navigate('/payment')}
      variant="outlined"
      style={{ textTransform: 'none' }}
    >
      Leave a tip
    </Button>
  );
}
