import { useState } from 'react';
import Button, {ButtonProps} from '@mui/material/Button';
import {useLocation} from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import {AuthProvider, useAuth} from "./authentication/Auth0";

function LoginButton(props: ButtonProps) {
  const {loginWithRedirect} = useAuth();
  const queryClient = useQueryClient();
  const pathname: string = useLocation().pathname;

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: pathname,
      },
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        scope: "user:full-access",
        prompt: "login",
      },
    });

    queryClient.invalidateQueries({
      queryKey: ['user'],
    });
  };

  return <Button 
    {...props} 
    onClick={handleLogin}
  >
    Sign In
  </Button>;
}


function LogoutButton(props: ButtonProps) {
  const {logout} = useAuth();

  const handleLogout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  return <Button 
    {...props} 
    onClick={handleLogout}
  >
    Sign Out
  </Button>;
}

export {AuthProvider, useAuth, LoginButton, LogoutButton};