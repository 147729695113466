import symbol from './symbol-logo.svg'
import text from './text-logo.svg'

const logo: {
  symbol: string,
  text: string,
} = {
  symbol: symbol,
  text: text,
}

const images = {logo}
export default images;